<template>
  <div class="center-container">
    <el-row :gutter="20">

      <el-col :span="12" :offset="6">
        <div style="margin: 10px 0">
          <el-button class="ml-5" type="success" @click="handleAdd" size="mid">新增书籍</el-button>
          <el-input size="mid" style="width: 300px;margin-left: 10px" placeholder="请输入名称" suffix-icon="el-icon-search"
                    v-model="name"></el-input>
          <el-button class="ml-5" type="success" @click="load" size="mid">搜索</el-button>
          <el-button class="ml-5" type="primary" @click="$router.push('/front/myDriftRecordBook')" size="mid">我的漂流
          </el-button>
        </div>
        <el-card v-for="item in tableData" :key="item.id" style="margin: 10px 0">
          <div>
            <div style="padding-left: 50px; flex: 1">
              <div style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px">
                <span style="font-size: 24px" @click="$router.push('/front/homeDetail?id=' + item.id)">{{
                    item.name
                  }}</span>
              </div>
              <div style="line-height: 30px">
                <div><b style="margin-right: 10px">作者：</b>{{ item.author }}</div>
                <div><b style="margin-right: 10px">出版社：</b>{{ item.publisher }}</div>
                <div><b style="margin-right: 10px">出版年份：</b>{{ item.publishedYear }}</div>
                <div><b style="margin-right: 10px">类型：</b>{{ item.typeText.name }}</div>
                <div><b style="margin-right: 10px">状态：</b>
                  <el-tag v-if="!item.driftRecord||item.driftRecord.status==='2'" type="success">未漂流</el-tag>
                  <el-tag v-if="item.driftRecord&&item.driftRecord.status==='0'" type="info">漂流中</el-tag>
                  <el-tag v-if="item.driftRecord&&item.driftRecord.status==='1'" type="warning">申请中</el-tag>
<!--                  <el-tag v-if="item.driftRecord&&item.driftRecord.status==='2'">正在阅读</el-tag>-->
                </div>
                <div style="text-align: right;" >
                  <el-button type="success" v-if="!item.driftRecord||item.driftRecord.status==='2'" @click="handleDrift(item)" slot="reference">放入漂流 <i class="el-icon-notebook-2"></i></el-button>
                  <el-button type="primary" @click="handleEdit(item)">编辑 <i class="el-icon-edit"></i></el-button>

                  <el-popconfirm
                      v-if="item.driftRecord&&item.driftRecord.status==='1'"
                      class="ml-5"
                      confirm-button-text='确定'
                      cancel-button-text='我再想想'
                      icon="el-icon-info"
                      icon-color="blue"
                      :title='`您确定通过吗？申请人：${item.driftRecord.user.nickname}`'
                      @confirm="update(item.driftRecord,2)"
                  >
                    <el-button type="primary" slot="reference">同意借阅 <i class="el-icon-notebook-1"></i></el-button>
                  </el-popconfirm>
                  <el-popconfirm
                    class="ml-5"
                    confirm-button-text='确定'
                    cancel-button-text='我再想想'
                    icon="el-icon-info"
                    icon-color="red"
                    title="您确定删除吗？"
                    @confirm="del(item.id)"
                >
                  <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
                </el-popconfirm>

                </div>
              </div>
            </div>
          </div>
        </el-card>
        <div style="margin: 10px 0; padding: 10px; background-color: #fff">
          <el-pagination
              size="mid"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[2, 5, 10, 20]"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="dialogForm" :model="form" label-width="100px" size="small" style="width: 90%">
        <el-form-item label="照片">
          <el-upload :action="uploadAction"
                     :file-list="form.fileList"
                     :on-success="handleImgUploadSuccess">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="form.type" placeholder="请选择类型" style="width: 100%">
            <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签">
          <el-select v-model="form.tag" multiple placeholder="请选择标签" style="width: 100%">
            <el-option
                v-for="item in tagOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="作者">
          <el-input v-model="form.author"></el-input>
        </el-form-item>
        <el-form-item label="出版年份">
          <el-date-picker v-model="form.publishedYear" type="datetime" value-format="yyyy-MM-dd" placeholder="选择日期时间"
                          style="width: 100%"></el-date-picker>
        </el-form-item>
        <el-form-item label="出版社">
          <el-input v-model="form.publisher"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <mavon-editor ref="md" v-model="form.remark" :ishljs="true" @imgAdd="imgAdd"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveBook">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="漂流" :visible.sync="drifre" width="30%" :close-on-click-modal="false">
      <el-form ref="dialogForm" :model="drifreForm" label-width="100px" size="small" style="width: 90%">
        <el-form-item label="联系方式">
          <el-input v-model="drifreForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="位置">
          <el-input v-model="drifreForm.address"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="drifre = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MyAnimal",
  data() {
    return {
      tableData: [],
      typeBookData: [],
      total: 0,
      uploadAction: process.env.VUE_APP_BASE_URL + '/file/upload',
      pageNum: 1,
      pageSize: 5,
      name: "",
      form: {},
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      drifreForm:{
      },
      typeOptions: [],
      tagOptions: [],
      users: [],
      dialogFormVisible: false,
      drifre: false,
      multipleSelection: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/book/myBook", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      // 类型
      this.request.get("/type").then(res => {
        this.typeOptions = res.data
      })
      // 标签
      this.request.get("/tag").then(res => {
        this.tagOptions = res.data
      })
      // 用户
      this.request.get("/user").then(res => {
        this.users = res.data
      })
    },
    // 绑定@imgAdd event
    imgAdd(pos, $file) {
      let $vm = this.$refs.md
      // 第一步.将图片上传到服务器.
      const formData = new FormData();
      formData.append('file', $file);
      axios({
        url: process.env.VUE_APP_BASE_URL + 'file/upload',
        method: 'post',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      }).then((res) => {
        console.log("上传成功")
        // 第二步.将返回的url替换到文本原位置![...](./0) -> ![...](url)
        let url = res.data.replace(process.env.VUE_APP_BASE_URL + "file", process.env.VUE_APP_BASE_URL + "file/preview")
        $vm.$img2Url(pos, url);
      })
    },
    save() {
      this.request.post("/driftRecord",this.drifreForm).then(res => {
        if (res.code === '200') {
          this.$message.success("成功")
          this.drifre = false;
          this.load()
        } else {
          this.$message.error("失败")
        }
      })
    },
    saveBook() {
      if (this.form.tag) {
        this.form.tag = this.form.tag.join(',');
      }
      this.form.userId = this.user.id;
      this.request.post("/book", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },

    update(record,status) {
      record.status = status;
      this.request.post("/driftRecord", record).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      console.log(this.drifreForm)

      this.form = {}
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    handleDrift(record) {
      this.drifre = true
      this.drifreForm = {
        "status":"0",
        "bookId":record.id,
      }
      this.drifreForm.phone =  this.user.phone
      this.drifreForm.address =  this.user.address
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      if (this.form.tag) {
        this.form.tag = this.form.tag.split(',').map(tag => tag.trim()); // 使用split方法将字符串分割为数组，并去除每个标签的前后空格
      }
      if (this.form.img){
        this.form.fileList = [{
          url: this.form.img,
          name: this.form.img.split('/').pop()
        }];
      }
      this.dialogFormVisible = true
      console.log(this.form)
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    del(id) {
      this.request.delete("/book/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    }
  }
}
</script>


<style>
.center-container {
  margin: 0 auto; /* 左右居中 */
  min-height: calc(100vh - 70px);
  padding: 10px;
}
</style>
